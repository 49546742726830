<template>
  <div class="pa-2">
    <section class="mb-4">
      <div v-if="tripRequest.approved && tripRequest.approval.approved" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-checkbox-marked-circle </v-icon>
        <h2>This trip has been approved.</h2>
      </div>
      <div v-else class="d-flex">
        <v-icon class="mr-2" color="blue"> mdi-alert-circle </v-icon>
        <h2>This trip requires approval.</h2>
      </div>
    </section>
    <div v-if="approvalLevelsDisplay.length">
      <!-- <template v-for="(level, i) of this.tripRequest.approval.requiredApprovalLevels">
        <v-tooltip bottom :key="i">
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="`subtitle-2 level ${levelColors[i] == 'blue' ? 'font-weight-bold' : ''}`"
              :style="`color: ${tripRequest.approval.approved ? 'success' : levelColors[i]}`"
              :key="i"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-1" color="green" v-show="tripRequest.approval.approved || levelColors[i] == 'green'">
                mdi-checkbox-marked-circle
              </v-icon>
              {{ level.name }}
            </div>
          </template>
          <span v-html="levelTooltips[i]"></span>
        </v-tooltip>

        <v-icon v-if="i < tripRequest.approval.requiredApprovalLevels.length - 1" :key="i + 5000">
          mdi-arrow-right-bold
        </v-icon>
      </template> -->
      <v-simple-table class="responsive-table">
        <thead>
          <tr>
            <th class="text-left">Level</th>
            <th class="text-left">Name</th>
            <th class="text-left approvers-width">Approvers</th>
            <th class="text-left">Status</th>
            <th class="text-left">Processed by</th>
            <th class="text-left" v-if="me.isTTAdmin">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(level, count) in approvalLevelsDisplay" :key="count">
            <td>{{ level.id }}</td>
            <td>
              <v-tooltip right contained color="#fff" class="ma-0 pa-0" :nudge-top="-10" tooltip-opacity="5">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="tooltip-trigger">{{ level.name }}</span>
                </template>
                <div class="mx-2">
                  <p class="font-weight-bold green--text text--darken-1" v-if="level.primaryApprovers.length > 1">
                    Approvers:
                    <span v-for="(approver, index) in level.primaryApprovers.slice(0, 5)" :key="index">
                      <template v-if="approver?.userEmail">
                        {{ approver.userEmail }}
                        <span v-if="index !== 4 && index !== level.primaryApprovers.length - 1">, </span>
                      </template>
                    </span>
                    <template v-if="level.primaryApprovers.length > 5">
                      + {{ level.primaryApprovers.length - 5 }} more
                    </template>
                  </p>
                  <p
                    class="font-weight-bold green--text text--darken-1"
                    v-else-if="level.primaryApprovers.length === 1"
                  >
                    Approver: {{ level.primaryApprovers[0]?.userEmail }}
                  </p>
                  <p class="font-weight-bold red--text text-accent-2" v-else>Cannot find approver email</p>
                </div>
              </v-tooltip>
            </td>
            <td>
              <v-chip class="ma-1" v-for="(primaryApprover, index) in level.primaryApprovers" :key="index">
                {{ primaryApprover.userDisplayName ?? '(unregistered)' }}</v-chip
              >
            </td>
            <td>{{ getStatus(level) }}</td>
            <td>{{ getActor(count) }}</td>
            <td v-if="me.isTTAdmin || me.is.transportationAdmin">
              <v-btn
                v-if="['Approved', 'Denied'].includes(getStatus(level))"
                color="primary"
                @click="showApprovalConfimation(level.id)"
              >
                Reinitiate Approval
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-dialog v-model="showApprovalConfimationDialog" max-width="500px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          Reinitiating the approval from this level would require all levels proceeding it to also approve.
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn color="error" @click="closeModal()">Cancel</v-btn>
          <v-btn color="success" @click="renitiateApproval()">Reinitiate Approval</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TripRequestApprovals',
  props: {
    tripRequest: {
      approval: {
        requiredApprovalLevels: Array,
      },
    },
  },
  data() {
    return {
      reinitateLevel: undefined,
      showApprovalConfimationDialog: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    approvalLevelsDisplay() {
      if (this.tripRequest.approval.requiredApprovalLevels) return this.tripRequest.approval.requiredApprovalLevels;
      return this.tripRequest.approval.deniedCanceledApprovalLevels;
    },
  },
  methods: {
    showApprovalConfimation(level) {
      this.reinitateLevel = level;
      this.showApprovalConfimationDialog = true;
    },

    closeModal() {
      this.reinitateLevel = undefined;
      this.showApprovalConfimationDialog = false;
    },

    getStatus(level) {
      if (!this.tripRequest.approval.history.length) return 'Not Approved';

      const record = this.tripRequest.approval.history.find((item) => item.approvalLevelId === level.id);

      if (record) {
        if (record.approved === -1) return 'Denied';
        return record.approved ? 'Approved' : 'Not Approved';
      }
      return 'Not Approved';
    },

    getActor(levelIndex) {
      const historyLength = this.tripRequest.approval.history.length;

      return this.tripRequest.approval.history[historyLength - levelIndex - 1]?.user;
    },

    getApprover(level) {
      const record = this.tripRequest.approval.requiredApprovalLevels.find((item) => item.id === level.approvalLevelId);
      return record.name;
    },

    renitiateApproval() {
      this.$emit('reinitateApproval', { tripId: this.tripRequest.id, level: this.reinitateLevel });
      this.closeModal();
    },
  },
};
</script>
<style scoped>
.tooltip-trigger {
  cursor: pointer;
}

.v-application .v-tooltip__content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding-top: 20px;
}

.approvers-width {
  width: 450px;
}
</style>
