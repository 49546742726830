<template>
  <v-toolbar-items>
    <v-btn text class="nav-button text-capitalize" @click="goBack">
      <v-icon left>mdi-arrow-left</v-icon>
      Back
    </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  name: 'TripRequestFormMenu',
  inject: ['eventHub'],
  components: {},
  data() {
    return {};
  },
  created() {
    this.eventHub.$on('leaveTripRequestForm', async ({ ok, to }) => {
      if (ok) this.$router.push(to);
      else {
        const yes = await this.$myconfirm('Are you sure you want to leave this page? Your changes will not be saved.');
        if (yes) this.$router.push(to);
      }
    });
  },
  beforeDestroy() {
    this.eventHub.$off('leaveTripRequestForm');
  },
  methods: {
    goBack() {
      this.eventHub.$emit('leaveTripRequestFormRequested');
    },
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
</style>
