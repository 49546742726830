<template>
  <div style="width: inherit">
    <v-toolbar height="50" class="my-2" elevation="4" rounded>
      <TripRequestFormMenu />
    </v-toolbar>

    <v-banner
      v-if="
        (!tripRequest.submittedUser && tripRequest.status < 1) ||
        (tripRequest.status == -1 && tripRequest.submittedUser)
      "
    >
      <template v-if="!tripRequest.submittedUser">
        <h4 class="text-h4">
          Trip Request Details<v-badge
            :value="!tripRequest.id"
            color="error"
            content="New"
            overlap
            offset-x="-10px"
            offset-y="-10px"
          ></v-badge>
        </h4>
        <v-label>Create or edit Trip Request</v-label>
      </template>
      <div v-else-if="tripRequest.status == -1 && tripRequest.submittedUser">
        <v-alert outlined class="amber--text text--darken-1 mb-0" icon="mdi-alert" text prominent dense>
          <h6 class="text-h6 d-inline-block mr-5">Changes Requested by {{ tripRequest.approval.history[0].user }}</h6>
          <span>Please make the required changes and resubmit this trip request.</span><br />
          <v-label>{{ tripRequest.approval.history[0].comments }}</v-label>
        </v-alert>
      </div>
    </v-banner>

    <div>
      <v-card :class="canShowTripSideMenu ? 'form nav-padding' : 'form'">
        <v-navigation-drawer v-if="canShowTripSideMenu" mini-variant permanent absolute right class="rounded-l-0">
          <v-list nav>
            <v-tooltip left v-for="item in sideNav" :key="item.name">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item link @click="$emit('setCard', item.name)" v-bind="attrs" v-on="on" :ripple="false">
                  <v-list-item-icon>
                    <v-badge :content="item.num" :value="!!item.num" color="red" overlap>
                      <v-icon :color="cardContent == item.name ? 'primary' : 'blue-grey'">{{ item.icon }}</v-icon>
                    </v-badge>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <span>{{ item.label }}</span>
            </v-tooltip>
          </v-list>
        </v-navigation-drawer>

        <v-card-text v-if="cardContent == 'tripRequest'" class="overflow-auto">
          <trip-request-stepper
            v-if="(tripRequest.status == 0 || tripRequest.status == -1) && !duplicated"
            :tripRequest="tripRequest"
            :complete="complete"
            :tripRequestConfig="tripRequestConfig"
            :filteredCFFs="filteredCFFs"
            :e1="e1"
            :showAdditionalInfo="showAdditionalInfo"
            @stepClick="$emit('stepClick', $event)"
            @save="$emit('save', $event)"
            @refreshTripRequest="$emit('refreshTripRequest', $event)"
            @tripTypeSelected="$emit('tripTypeSelected', $event)"
            @handlePreventSubmit="$emit('handlePreventSubmit', $event)"
            @handleCFFInput="$emit('handleCFFInput', $event)"
          ></trip-request-stepper>

          <trip-request-review
            v-else
            :tripRequest="tripRequest"
            :complete="complete"
            :tripRequestConfig="tripRequestConfig"
            :filteredCFFs="filteredCFFs"
            :e1="e1"
            :showAdditionalInfo="showAdditionalInfo"
            :duplicated="duplicated"
            @save="$emit('save', false, false, true)"
            @refreshTripRequest="$emit('refreshTripRequest', $event)"
            @tripTypeSelected="$emit('tripTypeSelected', $event)"
            @handlePreventSubmit="$emit('handlePreventSubmit', $event)"
            @handleCFFInput="$emit('handleCFFInput', $event)"
          ></trip-request-review>
        </v-card-text>

        <v-card-text v-if="cardContent == 'approvals'" class="card-content">
          <trip-request-approvals
            :tripRequest="tripRequest"
            @reinitateApproval="$emit('reinitateApproval', $event)"
          ></trip-request-approvals>
        </v-card-text>

        <v-card-text v-if="cardContent == 'assignments'" class="card-content">
          <trip-request-assignments
            ref="assignments"
            :assignments="tripRequest.assignments"
            :tripRequest="tripRequest"
            :tripRequestConfig="tripRequestConfig"
            @refreshTripRequest="$emit('refreshTripRequest')"
            @refreshTripRequests="getTripRequests"
          ></trip-request-assignments>
        </v-card-text>

        <v-card-text v-if="cardContent == 'comments'" class="card-content">
          <trip-request-comments
            ref="comments"
            :comments="tripRequest.userComments"
            :tripRequestId="tripRequest.id"
            @refreshTripRequest="$emit('refreshTripRequest')"
          ></trip-request-comments>
        </v-card-text>

        <v-card-text v-if="cardContent == 'emails'" class="card-content">
          <trip-request-emails
            ref="emails"
            :emails="tripRequest.userEmails"
            :tripRequestId="tripRequest.id"
            :requesterId="tripRequest.submittedUser"
            @refreshTripRequest="$emit('refreshTripRequest')"
          ></trip-request-emails>
        </v-card-text>

        <v-card-text v-if="cardContent == 'audit'" class="card-content">
          <trip-request-audit
            ref="audit"
            :auditHistory="tripRequest.auditHistory"
            :approval="tripRequest.approval"
          ></trip-request-audit>
        </v-card-text>

        <v-toolbar v-show="cardContent == 'tripRequest'" class="footer-border-top rounded-r-0" flat>
          <div ref="levelContainer" class="level-container" v-if="!isWrapping">
            <template
              v-if="tripRequest.status == 1 && tripRequest.permissions.canApprove && !tripRequest.approval.approved"
            >
              <v-btn class="mx-2 action-btn" color="error" @click="$emit('denyRequest')">Deny Request</v-btn>
              <v-btn class="mx-2 action-btn" color="warning" @click="$emit('requestChanges')">Request Changes</v-btn>
              <v-btn
                class="mx-2 action-btn"
                color="success"
                @click="$emit('approve')"
                :loading="approving"
                :disabled="approving"
              >
                Approve
                {{
                  tripRequest.approval && tripRequest.approval.awaitingApproval
                    ? tripRequest.approval.awaitingApproval.name
                    : ''
                }}
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <template
              v-if="
                tripRequestConfig.display.sendQuotes &&
                (tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER ||
                  tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR) &&
                !tripRequest.additionalTransportationId
              "
            >
              <v-btn class="mx-2 action-btn" color="primary" @click="$emit('requestQuote')"> Request For Quote </v-btn>
            </template>
            <v-btn v-if="showTripEstimator" class="mx-2 action-btn" color="primary" @click="$emit('openTripEstimator')">
              Trip Estimator
            </v-btn>
            <v-btn
              v-if="showDownloadPermissionSlip"
              class="mx-2 action-btn"
              color="primary"
              @click="$emit('permissionSlip')"
            >
              <v-icon>mdi-download</v-icon> Permission Slip
            </v-btn>

            <v-tooltip
              top
              v-if="tripRequest.status == 1"
              :disabled="
                !(
                  !canReschedule ||
                  (!me.is.superAdmin && !me.is.transportationAdmin && !me.is.limitedAdmin && cannotReschedule)
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    class="mx-2 action-btn"
                    color="primary"
                    @click="$emit('cancel')"
                    :disabled="
                      !canReschedule ||
                      (!me.is.superAdmin && !me.is.transportationAdmin && !me.is.limitedAdmin && cannotReschedule)
                    "
                  >
                    Cancel Trip
                  </v-btn>
                </div>
              </template>

              <span>{{
                cancelOrRescheduleMessage ||
                'If you would like to cancel this trip please contact your Transportation Director'
              }}</span>
            </v-tooltip>

            <v-tooltip
              top
              v-if="tripRequest.status == 1"
              :disabled="
                !(
                  !canReschedule ||
                  (!me.is.superAdmin && !me.is.transportationAdmin && !me.is.limitedAdmin && cannotReschedule)
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    v-if="tripRequest.status == 1"
                    class="mx-2 action-btn"
                    color="primary"
                    @click="$emit('reschedule')"
                    :disabled="
                      !canReschedule ||
                      (!me.is.superAdmin && !me.is.transportationAdmin && !me.is.limitedAdmin && cannotReschedule)
                    "
                  >
                    Reschedule
                  </v-btn>
                </div>
              </template>

              <span>{{
                cancelOrRescheduleMessage ||
                'If you would like to reschedule this trip please contact your Transportation Director'
              }}</span>
            </v-tooltip>

            <v-btn
              v-if="tripRequest.status == 1 && canCreateDuplicate"
              class="mx-2 action-btn"
              color="primary"
              @click="$emit('createDuplicate')"
            >
              Create Duplicate
            </v-btn>
          </div>

          <div v-else>
            <v-menu class="bottom-nav" offset-y top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="cardContent == 'tripRequest'"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2 mx-2"
                  color="primary"
                  v-if="tripRequest.status != 0 && !duplicated"
                >
                  Actions
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="$emit('denyRequest')"
                  v-if="tripRequest.status == 1 && tripRequest.permissions.canApprove && !tripRequest.approval.approved"
                >
                  <v-list-item-icon>
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Deny Request</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="$emit('requestChanges')"
                  v-if="tripRequest.status == 1 && tripRequest.permissions.canApprove && !tripRequest.approval.approved"
                >
                  <v-list-item-icon>
                    <v-icon color="warning">mdi-pencil-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Request Changes</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="$emit('approve')"
                  :loading="approving"
                  :disabled="approving"
                  v-if="tripRequest.status == 1 && tripRequest.permissions.canApprove && !tripRequest.approval.approved"
                >
                  <v-list-item-icon>
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    Approve
                    {{
                      tripRequest.approval && tripRequest.approval.awaitingApproval
                        ? tripRequest.approval.awaitingApproval.name
                        : ''
                    }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                  @click="$emit('requestQuote')"
                  v-if="
                    tripRequestConfig.display.sendQuotes &&
                    (tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER ||
                      tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR) &&
                    !tripRequest.additionalTransportationId
                  "
                >
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-file-document-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Request For Quote</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('openTripEstimator')" v-if="showTripEstimator">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Trip Estimator</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('permissionSlip')" v-if="showDownloadPermissionSlip">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Permission Slip</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                  @click="$emit('cancel')"
                  :disabled="
                    !canReschedule ||
                    (!me.is.superAdmin && !me.is.transportationAdmin && !me.is.limitedAdmin && cannotReschedule)
                  "
                >
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-cancel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cancel Trip</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="$emit('reschedule')"
                  :disabled="
                    !canReschedule ||
                    (!me.is.superAdmin && !me.is.transportationAdmin && !me.is.limitedAdmin && cannotReschedule)
                  "
                >
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-calendar-edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Reschedule</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('createDuplicate')" v-if="tripRequest.status == 1 && canCreateDuplicate">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-content-duplicate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Create Duplicate</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-spacer></v-spacer>

          <v-btn
            v-if="
              tripRequest.status == 1 &&
              (tripRequest.permissions.canEdit ||
                tripRequest.permissions.canEditSiteAdmin ||
                tripRequest.permissions.canEditApprover ||
                tripRequest.permissions.canEditPreApproval ||
                tripRequest.permissions.canEditMidApproval ||
                tripRequest.permissions.canEditApproved)
            "
            class="mx-2"
            color="success"
            @click="$emit('save', true)"
            :disabled="preventSubmit"
          >
            Save
          </v-btn>

          <template v-if="tripRequest.status != 1 && !duplicated">
            <v-btn class="nav-buttons" color="primary" v-show="e1 > 1" @click="$emit('prev')" :disabled="preventSubmit">
              Prev
            </v-btn>
            <v-btn
              class="nav-buttons"
              color="primary"
              v-show="showNext"
              @click="$emit('next')"
              :disabled="preventSubmit"
            >
              Next
            </v-btn>
          </template>

          <div v-if="showSubmit">
            <v-btn
              v-if="
                tripRequestConfig.display.sendQuotes &&
                tripRequestConfig.display.contractors &&
                (tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER ||
                  tripRequest.vehicleType == VEHICLE_TYPES.CO) &&
                !tripRequest.additionalTransportationId
              "
              class="mx-2"
              color="primary"
              :loading="saving"
              :disabled="preventSubmit || saving"
              @click="$emit('save', false, true)"
            >
              Save as Draft
            </v-btn>

            <v-tooltip top v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="nav-buttons"
                  color="primary"
                  @click="$emit('submit')"
                  :disabled="preventSubmit || submitting"
                >
                  Submit
                </v-btn>
              </template>
              <span>{{ tripRequestConfig.messages.final }}</span>
            </v-tooltip>
          </div>
        </v-toolbar>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_TRIP_REQUESTS } from '@/store/modules/TripRequest/actions';
import TripRequestFormMenu from './Menu.vue';
import TripRequestStepper from './Detail.Stepper.vue';
import TripRequestReview from './Detail.Review.vue';
import TripRequestApprovals from './Detail.Approvals.vue';
import TripRequestAssignments from './TripRequestAssignments.vue';
import TripRequestAudit from './Detail.AuditHistory.vue';
import TripRequestComments from './Detail.Comments.vue';
import TripRequestEmails from './Detail.Emails.vue';
import VEHICLE_TYPES from '@/shared/types';
import { TRIP_STATUS } from '@/shared/common';

export default {
  name: 'RequestDetailDesktop',
  inject: ['eventHub'],
  components: {
    TripRequestFormMenu,
    TripRequestStepper,
    TripRequestReview,
    TripRequestApprovals,
    TripRequestAssignments,
    TripRequestAudit,
    TripRequestComments,
    TripRequestEmails,
  },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    complete: Object,
    filteredCFFs: Object,
    e1: Number,
    cardContent: String,
    duplicated: Boolean,
    showAdditionalInfo: Boolean,
    showTripEstimator: Boolean,
    showDownloadPermissionSlip: Boolean,
    preventSubmit: Boolean,
    submitting: Boolean,
    showNext: Boolean,
    showSubmit: Boolean,
    levelTooltips: Object,
    sideNav: Array,
    approving: Boolean,
    canReschedule: Boolean,
    cannotReschedule: Boolean,
    cancelOrRescheduleMessage: String,
    canCreateDuplicate: Boolean,
    levelColors: Object,
  },
  data() {
    return {
      VEHICLE_TYPES,
      isWrapping: false,
      TRIP_STATUS,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    canShowTripSideMenu() {
      return (
        this.tripRequest.status != this.TRIP_STATUS.DRAFT &&
        this.tripRequest.status != this.TRIP_STATUS.RESUBMIT &&
        !this.duplicated
      );
    },
  },
  methods: {
    ...mapActions('tripRequest', [GET_TRIP_REQUESTS]),
    checkWrapping() {
      this.$nextTick(() => {
        const container = this.$refs.levelContainer;
        if (container) {
          const children = container.querySelectorAll('.action-btn');
          let isWrapping = false;

          if (children.length > 1) {
            const firstChildOffsetTop = children[0].offsetTop;
            for (let i = 1; i < children.length; i++) {
              if (children[i].offsetTop !== firstChildOffsetTop) {
                isWrapping = true;
                break;
              }
            }
          }

          this.isWrapping = isWrapping;
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.checkWrapping();
    window.addEventListener('resize', this.checkWrapping);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWrapping);
  },
};
</script>

<style scoped>
.form {
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 84px - 94px + 12px);
  min-height: 200px;
}
.nav-padding {
  padding-right: 56px;
}
.card-content {
  min-height: 400px;
  overflow-y: auto;
  max-height: calc(100vh - 84px - 94px);
}
.nav-buttons {
  margin: 0 6px;
}
.trip-navigation {
  background-color: #4d505b;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 0px 5px 5px 0px;
}
.level-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.level {
  display: inline-block;
}
.footer-border-top {
  border-top: 1px solid rgb(134, 140, 160);
}
</style>
