<template>
  <v-container fluid style="height: 100%" class="px-8">
    <div class="container" v-if="loading">
      <v-progress-circular :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
    </div>

    <template v-else>
      <request-detail-desktop
        v-if="!isMobile"
        :tripRequest="tripRequest"
        :tripRequestConfig="tripRequestConfig"
        :complete="complete"
        :filteredCFFs="filteredCFFs"
        :e1="e1"
        :cardContent="cardContent"
        :duplicated="duplicated"
        :showAdditionalInfo="showAdditionalInfo"
        :showTripEstimator="!!showTripEstimator"
        :showDownloadPermissionSlip="showDownloadPermissionSlip"
        :preventSubmit="preventSubmit"
        :submitting="submitting"
        :showNext="showNext"
        :showSubmit="showSubmit"
        :levelTooltips="levelTooltips"
        :sideNav="sideNav"
        :approving="approving"
        :canReschedule="canReschedule"
        :cannotReschedule="cannotReschedule"
        :cancelOrRescheduleMessage="cancelOrRescheduleMessage"
        :canCreateDuplicate="canCreateDuplicate"
        :levelColors="levelColors"
        @save="save"
        @next="next"
        @prev="prev"
        @refreshTripRequest="refreshTripRequest"
        @stepClick="stepClick"
        @setCard="setCard"
        @openTripEstimator="openTripEstimator"
        @permissionSlip="permissionSlip"
        @cancel="cancel"
        @reschedule="reschedule"
        @createDuplicate="createDuplicate"
        @submit="submit"
        @denyRequest="denyRequest"
        @requestChanges="requestChanges"
        @approve="approve"
        @requestQuote="requestQuote"
        @tripTypeSelected="tripTypeSelected"
        @handlePreventSubmit="handlePreventSubmit"
        @handleCFFInput="handleCFFInput"
        @reinitateApproval="reinitateApproval"
      ></request-detail-desktop>

      <request-detail-mobile
        v-else
        style="height: 100%"
        :tripRequest="tripRequest"
        :tripRequestConfig="tripRequestConfig"
        :complete="complete"
        :filteredCFFs="filteredCFFs"
        :e1="e1"
        :cardContent="cardContent"
        :duplicated="duplicated"
        :showAdditionalInfo="showAdditionalInfo"
        :showTripEstimator="!!showTripEstimator"
        :showDownloadPermissionSlip="showDownloadPermissionSlip"
        :preventSubmit="preventSubmit"
        :submitting="submitting"
        :showNext="showNext"
        :showSubmit="showSubmit"
        :levelTooltips="levelTooltips"
        :sideNav="sideNav"
        :approving="approving"
        :canReschedule="canReschedule"
        :cannotReschedule="cannotReschedule"
        :cancelOrRescheduleMessage="cancelOrRescheduleMessage"
        :canCreateDuplicate="canCreateDuplicate"
        :levelColors="levelColors"
        @save="save"
        @next="next"
        @prev="prev"
        @refreshTripRequest="refreshTripRequest"
        @stepClick="stepClick"
        @setCard="setCard"
        @openTripEstimator="openTripEstimator"
        @permissionSlip="permissionSlip"
        @cancel="cancel"
        @reschedule="reschedule"
        @createDuplicate="createDuplicate"
        @submit="submit"
        @denyRequest="denyRequest"
        @requestChanges="requestChanges"
        @approve="approve"
        @requestQuote="requestQuote"
        @tripTypeSelected="tripTypeSelected"
        @handlePreventSubmit="handlePreventSubmit"
        @handleCFFInput="handleCFFInput"
      ></request-detail-mobile>
    </template>

    <step-trip-acceptance
      ref="acceptance"
      :tripRequest="tripRequest"
      :tripRequestConfig="tripRequestConfig"
      @refreshTripRequest="refreshTripRequest"
      @submit="handleSubmit"
    ></step-trip-acceptance>

    <cancel-trip ref="cancel" :tripRequest="tripRequest" @refreshTripRequests="getTripRequests"></cancel-trip>

    <trip-estimator ref="tripEstimator" :tripRequest="tripRequest"></trip-estimator>

    <reschedule-trip
      ref="reschedule"
      :tripRequest="tripRequest"
      @refreshTripRequest="refreshTripRequest"
      @refreshTripRequests="getTripRequests"
    ></reschedule-trip>

    <request-quote
      ref="quote"
      :tripRequest="tripRequest"
      :type="tripRequest.vehicleTypeId"
      :defaultMessage="tripRequestConfig.messages.requestQuote"
    ></request-quote>

    <deny-modal ref="deny" :tripRequest="tripRequest"></deny-modal>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { differenceInCalendarDays } from 'date-fns';
import {
  GET_TRIP_REQUEST,
  GET_TRIP_REQUESTS,
  GET_TRIP_REQUEST_COMPLETE_STATUS,
  SAVE_TRIP_REQUEST,
  DELETE_TRIP_REQUEST,
  APPROVE_TRIP_REQUEST,
  DUPLICATE_TRIP_REQUEST,
  DOWNLOAD_PERMISSION_SLIP,
  RESET_APPROVAL,
} from '@/store/modules/TripRequest/actions';
import { GET_CONFIG } from '@/store/modules/Config/actions';
import { randomString } from '@/util';
import { TRIP_CATEGORIES, TRIP_STATUS } from '@/shared/common';

import RequestDetailDesktop from './Detail.Desktop.vue';
import RequestDetailMobile from './Detail.Mobile.vue';
import StepTripAcceptance from './Step.TripAcceptance.vue';
import RequestQuote from './RequestQuote.vue';
import CancelTrip from '@/components/CancelTrip.vue';
import TripEstimator from './TripEstimator.vue';
import RescheduleTrip from '@/components/Reschedule.vue';
import DenyModal from './DenyModal.vue';

export default {
  name: 'RequestDetail',
  inject: ['eventHub'],
  components: {
    RequestDetailDesktop,
    RequestDetailMobile,
    StepTripAcceptance,
    CancelTrip,
    TripEstimator,
    RescheduleTrip,
    RequestQuote,
    DenyModal,
  },
  props: ['id'],
  data() {
    return {
      differenceInCalendarDays,
      loading: true,
      saving: false,
      approving: false,
      e1: 1,
      cardContent: 'tripRequest',
      requiredApprovalLevels: [],
      levelColors: {},
      tripRequest: {
        tripEventIds: [],
        approval: {},
        additionalStops: { depart: [], return: [] },
        customFormFields: {},
        fundingSources: [],
        permissions: {},
        needDriverAssigned: null,
        status: 0,
        vehDriverInfo: '',
      },
      customFormFields: {},
      rolePermissions: {},
      filteredCFFs: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] },
      complete: {
        general: false,
        leaveReturn: false,
        destination: false,
        attendees: false,
        transportation: false,
        fundingSource: false,
        additional: false,
        supporting: false,
      },
      tripRequestConfig: {
        labels: {},
        messages: {},
        display: {
          emergencyContact: [],
          healthConcerns: [],
          awayForLunch: [],
          permissionsSlip: [],
          specialIndicators: [],
        },
        chaperoneRules: {},
        other: {},
      },
      showAdditionalInfo: true,
      preventSubmit: false,
      submitting: false,
      redirectToDashboard: false,
      redirectToCalendar: false,
      redirectToAssignments: false,
      tripStep: {
        General: 1,
        LeaveReturn: 2,
        Destination: 3,
        Attendees: 4,
        Transportation: 5,
        FundingSource: 6,
        AdditionalOrSupporting: 7,
        Supporting: 8,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'hasPermissionSlipEnglishTemplate', 'hasPermissionSlipSpanishTemplate']),
    ...mapGetters('user', ['me']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('config', ['vehicleOwnerConfig', 'districtWideRates', 'fuelPrices']),
    sideNav() {
      const sn = [
        { name: 'tripRequest', icon: 'mdi-list-box', label: 'Trip Details' },
        { name: 'approvals', icon: 'mdi-check-decagram', label: 'Approvals' },
        {
          name: 'comments',
          icon: 'mdi-comment',
          label: 'Comments',
          num: this.tripRequest && this.tripRequest.userComments ? this.tripRequest.userComments.length : 0,
        },
        {
          name: 'emails',
          icon: 'mdi-email',
          label: 'Emails',
          num: this.tripRequest && this.tripRequest.userEmails ? this.tripRequest.userEmails.length : 0,
        },
        { name: 'audit', icon: 'mdi-history', label: 'Audit History' },
      ];
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        (this.tripRequest.canAssign &&
          (this.tripRequest.permissions.canAssign ||
            this.tripRequest.permissions.canEditSiteAdmin ||
            this.tripRequest.permissions.canEditApprover ||
            (this.tripRequest.status == TRIP_STATUS.CANCELLED && this.vehicleOwnerConfig.assignCanceled) ||
            this.me.is.vehicleOwner ||
            this.me.is.specialNeedsVehicleOwner ||
            this.me.is.siteAdmin ||
            this.me.is.siteAdminReadOnly ||
            this.me.is.schoolFinance))
      )
        sn.splice(2, 0, { name: 'assignments', icon: 'mdi-format-list-checks', label: 'Assignments' });
      return sn;
    },
    fileRequired() {
      return (
        this.tripRequestConfig.other.requireAttachmentsTripTypes?.includes(this.tripRequest.tripTypeId) ||
        this.tripRequestConfig.other.requireAttachmentsVehicleTypes?.includes(this.tripRequest.vehicleTypeId)
      );
    },
    levelTooltips() {
      let tt = {};
      for (let i = 0; i < this.requiredApprovalLevels.length; i++) {
        tt[i] = this.requiredApprovalLevels[i].primaryApprovers
          ? this.requiredApprovalLevels[i].primaryApprovers
              .map((e) => `${e.userDisplayName} (${e.userEmail})`)
              .join('<br>')
          : [];
      }
      return tt;
    },
    duplicated() {
      return this.tripRequest.status == TRIP_STATUS.DRAFT && this.$route.params.id != 0;
    },
    showNext() {
      if (this.tripRequest.status < TRIP_STATUS.RESUBMIT) return false;
      if (this.showAdditionalInfo) return this.e1 < 8;
      else return this.e1 < 7;
    },
    showSubmit() {
      return (
        (!this.showNext || this.duplicated) &&
        (this.tripRequest.status == TRIP_STATUS.DRAFT || this.tripRequest.status == TRIP_STATUS.RESUBMIT)
      );
    },
    canReschedule() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        (this.me.is.siteAdmin && this.rolePermissions.siteAdmin.rescheduleTrips) ||
        (this.me.is.approver && this.rolePermissions.approver.rescheduleTrips) ||
        (this.me.is.vehicleOwner && this.rolePermissions.vehicleOwner.rescheduleTrips) ||
        (this.me.is.specialNeedsVehicleOwner && this.rolePermissions.specialNeedsVehicleOwner.rescheduleTrips) ||
        (this.me.is.requester && this.rolePermissions.requester.rescheduleTrips)
      );
    },
    cannotReschedule() {
      if (
        this.tripRequestConfig.display.limitReschedule &&
        differenceInCalendarDays(new Date(this.tripRequest.leaveDate), new Date()) <=
          this.tripRequestConfig.display.limitRescheduleDays
      ) {
        return true;
      }
      return false;
    },
    cancelOrRescheduleMessage() {
      return this.tripRequestConfig.display.limitRescheduleMessage;
    },
    canCreateDuplicate() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAmin ||
        this.me.is.limitedAdmin ||
        this.me.is.siteAdmin ||
        this.me.is.approver ||
        this.me.is.vehicleOwner
      )
        return true;
      if (this.tripRequestConfig && this.tripRequestConfig.display.duplicate) return true;
      else return false;
    },
    showTripEstimator() {
      return this.tripRequestConfig.tripEstimate.display && this.tripRequest.submittedTimestamp;
    },
    showDownloadPermissionSlip() {
      if (
        (this.hasPermissionSlipEnglishTemplate || this.hasPermissionSlipSpanishTemplate) &&
        this.tripRequestConfig.templates.showDownloadPermissionSlip &&
        (this.tripRequest.approved || this.tripRequest.approval.approved)
      )
        return true;
      return false;
    },
    validateSteps() {
      return {
        1: 'validateStepGeneral',
        2: 'validateStepLeaveReturn',
        3: 'validateStepDestination',
        4: 'validateStepAttendees',
        5: 'validateStepTransportation',
        6: 'validateStepFundingSource',
        7: this.showAdditionalInfo ? 'validateStepAdditional' : 'validateStepSupporting',
        8: 'validateStepSupporting',
      };
    },
  },
  created() {
    this.eventHub.$on('leaveTripRequestFormRequested', () => this.leaveTripRequest());
    this.eventHub.$on('refreshTripRequest', (id, callback = () => {}) => this.refreshTripRequest(id, callback));
    this.eventHub.$on('saveTripRequest', (adminSave, draft, doNotAudit, callback = () => {}) =>
      this.save(adminSave, draft, doNotAudit, callback)
    );

    this.fetchItems();

    if (this.$router.history.current.query.dashboard) this.redirectToDashboard = true;
    if (this.$router.history.current.query.calendar) this.redirectToCalendar = true;
    if (this.$router.history.current.query.assignment) {
      this.redirectToAssignments = true;
      this.cardContent = 'assignments';
    }
  },
  beforeDestroy() {
    this.eventHub.$off('leaveTripRequestFormRequested');
    this.eventHub.$off('saveTripRequest');
    this.eventHub.$off('refreshTripRequest');
  },
  methods: {
    ...mapActions('tripRequest', [
      GET_TRIP_REQUEST,
      GET_TRIP_REQUESTS,
      GET_TRIP_REQUEST_COMPLETE_STATUS,
      SAVE_TRIP_REQUEST,
      DELETE_TRIP_REQUEST,
      RESET_APPROVAL,
      APPROVE_TRIP_REQUEST,
      DUPLICATE_TRIP_REQUEST,
      DOWNLOAD_PERMISSION_SLIP,
    ]),
    ...mapMutations('tripRequest', ['updateTripRequest', 'setCurrentTripRequest']),
    ...mapMutations('assignment', ['updateAssignment']),
    ...mapActions('config', [GET_CONFIG]),
    fundingSourcesWithIds(fundingSources) {
      return fundingSources.filter((item) => item.fundingSourceId > 0);
    },
    async fetchItems() {
      this.tripRequestConfig = await this.getConfig('tripRequestForm');
      this.customFormFields = await this.getConfig('customFormFields');
      this.rolePermissions = await this.getConfig('permissions');

      await this.refreshTripRequest();

      if (this.tripRequest.status > TRIP_STATUS.DRAFT) this.eventHub.$emit('recalculateRoutes');
    },
    setCard(option) {
      this.cardContent = option;
    },
    async stepClick(stepNo) {
      if (this.tripRequest.status != TRIP_STATUS.DRAFT) {
        this.e1 = !this.showAdditionalInfo && stepNo == 8 ? 7 : stepNo;
      }
    },
    async next() {
      if (
        this.e1 === this.tripStep.LeaveReturn &&
        this.tripRequest.status == TRIP_STATUS.DRAFT &&
        this.tripRequestConfig.display.overnightOOSRequired &&
        this.tripRequest.outOfState == null
      ) {
        this.$myalert.error(
          'Out Of State/Overnight question not answered. Please complete this section before moving on'
        );
        return;
      } else if (
        this.e1 === this.tripStep.Attendees &&
        this.tripRequest.status == TRIP_STATUS.DRAFT &&
        this.tripRequestConfig.display.healthConcerns.includes(this.tripRequest.tripTypeId) &&
        this.tripRequestConfig.display.requireHealthConcerns &&
        this.tripRequest.haveHealthConcerns == null
      ) {
        this.$myalert.error('Health Details not provided. Please complete this section before moving on');
        return;
      }

      if (this.e1 === this.tripStep.Transportation) {
        // if no value is provided, default value would be yes (1)
        if (!this.tripRequestConfig.display.requireDriverInfo && this.tripRequest.needDriverAssigned === null) {
          this.tripRequest.needDriverAssigned = 1;
        }
      }

      if (
        !this.tripRequest.status ||
        this.tripRequest.status === TRIP_STATUS.DRAFT ||
        this.tripRequest.status === TRIP_STATUS.RESUBMIT
      ) {
        await this.save();
      }

      await this.refreshTripRequest();

      const completeByStep = {
        1: this.complete.general,
        2: this.complete.leaveReturn,
        3: this.complete.destination,
        4: this.complete.attendees,
        5: this.complete.transportation,
        6: this.complete.fundingSource,
        7: this.showAdditionalInfo ? this.complete.additional : this.complete.supporting,
        8: this.complete.supporting,
      };

      if (this.e1 === this.tripStep.Destination) this.eventHub.$emit('recalculateRoutes');

      if (
        this.e1 === this.tripStep.Attendees &&
        ((this.tripRequest.category === TRIP_CATEGORIES.STAFF_ONLY && this.tripRequest.totalAdults < 1) ||
          (this.tripRequest.category !== TRIP_CATEGORIES.STAFF_ONLY &&
            (this.tripRequest.totalAdults < 1 || this.tripRequest.totalStudents < 1)))
      ) {
        this.$myalert.error('Attendee Count not provided. Please complete this section before moving on');
        this.eventHub.$emit(this.validateSteps[this.e1]);
      } else if (this.e1 === this.tripStep.Supporting && this.fileRequired && !this.tripRequest.hasAttachment) {
        this.$myalert.error('Please complete this section before moving on');
        this.eventHub.$emit(this.validateSteps[this.e1]);
      } else if (this.e1 === this.tripStep.FundingSource) {
        const requesterRequiresFundingSource =
          this.tripRequestConfig?.display?.showFunding &&
          (this.tripRequestConfig?.display?.requesterFundingSourceRequired || []).includes(this.tripRequest.tripTypeId);

        const approverRequiresFundingSource =
          this.me.is.approver &&
          this.tripRequest.status !== TRIP_STATUS.DRAFT &&
          (this.tripRequestConfig?.display?.approverFundingSourceRequired || []).includes(this.tripRequest.tripTypeId);

        if (
          (requesterRequiresFundingSource || approverRequiresFundingSource) &&
          !this.tripRequest.fundingSources?.length
        ) {
          this.$myalert.error('Please complete this section before moving on');
          this.eventHub.$emit(this.validateSteps[this.e1]);

          return;
        }

        this.e1 += 1;
      } else if (completeByStep[this.e1]) {
        this.e1 += 1;
        this.eventHub.$emit(this.validateSteps[this.e1], true);
      } else {
        this.$myalert.error('Please complete this section before moving on');
        this.eventHub.$emit(this.validateSteps[this.e1]);
      }
    },
    prev() {
      this.e1 += -1;
    },
    async refreshTripRequest(id, callback = () => {}) {
      id = id || this.id;

      if (id > 0) {
        const { tripRequest } = await this.getTripRequest(id);

        if (!tripRequest || (tripRequest.status != 0 && !tripRequest.permissions.canView)) {
          this.$router.push('/trip-requests');
          return;
        }

        if (tripRequest.status > 0) {
          this.updateTripRequest(tripRequest);

          if (tripRequest.assignments) {
            for (let a of tripRequest.assignments) {
              this.updateAssignment(a);
            }
          }
        }

        this.tripRequest = tripRequest;
        this.setCurrentTripRequest(tripRequest);

        if (this.tripRequest.id) {
          this.tripRequest.gradeLevels = tripRequest.gradeLevels ? tripRequest.gradeLevels.map((e) => String(e)) : [];

          if (
            this.e1 === this.tripRequest.Attendees &&
            this.tripRequest.status == TRIP_STATUS.DRAFT &&
            this.tripRequestConfig.display.healthConcerns.includes(this.tripRequest.tripTypeId) &&
            this.tripRequestConfig.display.requireHealthConcerns
          )
            this.tripRequest.haveHealthConcerns = null;
        }

        this.complete =
          this.tripRequest.status == TRIP_STATUS.DRAFT || this.tripRequest.status == TRIP_STATUS.RESUBMIT
            ? await this.getTripRequestCompleteStatus({
                ...this.tripRequest,
                step: this.e1,
                includeAdditionalInfo: this.showAdditionalInfo,
              })
            : {
                general: true,
                leaveReturn: true,
                destination: true,
                attendees: true,
                transportation: true,
                fundingSource: true,
                additional: true,
                supporting: true,
              };

        if (this.tripRequest.status == TRIP_STATUS.SUBMITTED) this.setRequiredApprovalLevels(this.tripRequest.approval);

        this.checkAdditionalInfo();

        if (this.tripRequest.status != TRIP_STATUS.DRAFT) this.updateTripRequest(this.tripRequest);
      } else {
        // new trip request
        if (!this.tripRequestConfig.display.extTransportation) {
          this.tripRequest.needDistrictVehicles = 1;
        }
      }

      if (this.e1 === this.tripRequest.General) {
        setTimeout(() => {
          this.eventHub.$emit('stepGeneralRequested');
        }, 100);
      }

      this.loading = false;

      if (typeof callback === 'function') callback();
    },
    checkAdditionalInfo() {
      if (this.tripRequest.tripTypeId)
        this.showAdditionalInfo =
          this.tripRequestConfig.display.educationalObjective.includes(this.tripRequest.tripTypeId) ||
          this.tripRequestConfig.display.specialIndicators.includes(this.tripRequest.tripTypeId) ||
          this.tripRequestConfig.display.substitute ||
          this.tripRequestConfig.display.permissionSlip.includes(this.tripRequest.tripTypeId) ||
          this.customFormFields[6].length > 0;
    },
    handlePreventSubmit(option) {
      if (!this.me.is.superAdmin && !this.me.is.transportationAdmin) this.preventSubmit = option;
    },
    handleSubmit(option) {
      this.submitting = option;
    },
    tripTypeSelected(tripTypeId) {
      for (let i = 0; i < 8; i++)
        this.filteredCFFs[i] = this.customFormFields[i].filter(
          (e) => e.tripTypeIds.includes(tripTypeId) && (e.oos ? this.tripRequest.outOfState : true)
        );
      this.checkAdditionalInfo();
    },
    handleCFFInput({ cffId, value }) {
      this.tripRequest.customFormFields[cffId] = value;
    },
    setRequiredApprovalLevels(approval) {
      this.requiredApprovalLevels = approval.requiredApprovalLevels;
      const awaitingIndex = this.tripRequest.approval.awaitingApproval
        ? this.requiredApprovalLevels.findIndex(
            (e) =>
              e.id == this.tripRequest.approval.awaitingApproval.id &&
              e.name == this.tripRequest.approval.awaitingApproval.name
          )
        : -1;
      for (var i = 0; i < this.requiredApprovalLevels.length; i++) {
        if (i < awaitingIndex) this.levelColors[i] = 'green';
        else if (i > awaitingIndex) this.levelColors[i] = 'black';
        else this.levelColors[i] = 'blue';
      }
    },
    async save(adminSave, draft, doNotAudit, callback) {
      if (!this.tripRequest.category || !this.tripRequest.locationId) {
        this.saving = false;
        return;
      }

      this.tripRequest.destinationId = this.tripRequest.destinationId
        ? this.tripRequest.destinationId
        : this.tripRequest.destination?.id;

      try {
        if (this.tripRequest.fundingSources && this.tripRequest.fundingSources.length) {
          this.tripRequest.fundingSources = this.fundingSourcesWithIds(this.tripRequest.fundingSources);
        }

        this.saving = true;

        const r = await this.saveTripRequest({
          ...this.tripRequest,
          status: draft ? TRIP_STATUS.RESUBMIT : this.tripRequest.status,
          savedAsDraft: draft,
          doNotAudit,
        });

        if (!r || !r.id) return;

        if (adminSave) this.$myalert.success('Trip Request saved');

        if (draft) {
          await this.getTripRequests();

          this.$myalert.success(`Trip Request #${r.id} saved as draft`);
          this.$router.push('/trip-requests');

          return;
        }

        await this.refreshTripRequest(r.id);

        if (typeof callback === 'function') callback();
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.saving = false;
      }
    },
    async submit() {
      if (this.e1 === this.tripStep.Supporting && this.fileRequired && !this.tripRequest.hasAttachment) {
        this.$myalert.error('Please complete this section before moving on');
        this.eventHub.$emit(this.validateSteps[this.e1]);

        return;
      }

      await this.save();

      this.complete = await this.getTripRequestCompleteStatus({
        ...this.tripRequest,
        step: this.duplicated ? 10 : this.e1,
        includeAdditionalInfo: this.showAdditionalInfo,
      });

      if (
        this.complete.general &&
        this.complete.leaveReturn &&
        this.complete.destination &&
        this.complete.attendees &&
        this.complete.transportation &&
        this.complete.fundingSource &&
        (this.showAdditionalInfo ? this.complete.additional : true) &&
        this.complete.supporting
      ) {
        await this.$refs.tripEstimator.estimate(true);
        this.$refs.acceptance.dialog = true;
      } else this.$myalert.error('Please complete the required fields');
    },
    async approve() {
      this.approving = true;

      await this.save();

      try {
        const r = await this.approveTripRequest({
          tripRequestId: this.tripRequest.id,
          approvalLevelId: this.tripRequest.approval.awaitingApproval.id,
          approved: 1,
        });
        if (r && r.id == -1) {
          const messages = r.error.map((error) => error.message);
          return this.$myalert.error(messages);
        }

        this.refreshTripRequest(this.tripRequest.id);
        this.$myalert.success('Approved');

        if (r.message) setTimeout(() => this.$myalert.warning(r.message), 500);
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.approving = false;
      }
    },
    requestQuote() {
      this.$refs.quote.dialog = true;
    },
    requestChanges() {
      this.$refs.deny.changesNeeded = true;
      this.$refs.deny.dialog = true;
    },
    denyRequest() {
      this.$refs.deny.changesNeeded = false;
      this.$refs.deny.dialog = true;
    },
    leaveTripRequest() {
      let to = '/trip-requests';
      if (this.redirectToDashboard) to = '/dashboard';
      else if (this.redirectToCalendar) to = '/calendars';
      else if (this.redirectToAssignments) to = '/assignments';
      this.eventHub.$emit('leaveTripRequestForm', {
        ok: this.tripRequest.status != TRIP_STATUS.DRAFT && this.tripRequest.status != TRIP_STATUS.RESUBMIT,
        to,
      });
    },
    openTripEstimator() {
      this.$refs.tripEstimator.dialog = true;
    },
    async permissionSlip() {
      let inEnglish = this.hasPermissionSlipEnglishTemplate;
      if (this.hasPermissionSlipEnglishTemplate && this.hasPermissionSlipSpanishTemplate)
        inEnglish = await this.$myconfirm({
          message: 'Which language would you like to download the Permission Slip in?',
          yes: 'English',
          no: 'Spanish',
        });
      try {
        await this.downloadPermissionSlip({
          tripRequestId: this.tripRequest.id,
          uuid: randomString(16),
          reportId: 0,
          language: inEnglish ? 'english' : 'spanish',
        });
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    cancel() {
      this.$refs.cancel.dialog = true;
    },
    reschedule() {
      this.$refs.reschedule.dialog = true;
    },
    async createDuplicate() {
      try {
        const r = await this.duplicateTripRequest(this.tripRequest.id);
        if (r && r.id) {
          this.cardContent = 'tripRequest';
          this.$router.push(`/trip-request/${r.id}?duplicate=${this.tripRequest.id}`);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.submitting = false;
      }
    },
    async reinitateApproval(trip) {
      await this.resetApproval(trip);
      this.refreshTripRequest();
    },
  },
  watch: {
    id() {
      this.refreshTripRequest();
    },
    e1(value) {
      switch (value) {
        case 1:
          this.eventHub.$emit('stepGeneralRequested');
          break;
        case 3:
          this.eventHub.$emit('stepDestinationRequested');
          break;
        case 6:
          this.eventHub.$emit('stepFundingSourceRequested');
          break;
        case 7:
          if (!this.showAdditionalInfo) this.eventHub.$emit('stepSupportingRequest');
          break;
        case 8:
          this.eventHub.$emit('stepSupportingRequest');
          break;
        default:
          break;
      }
    },
    tripRequest(value) {
      if (value.tripTypeId) this.checkAdditionalInfo();
    },
  },
  destroyed() {
    this.setCurrentTripRequest(null);
  },
};
</script>

<style scoped>
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
.container {
  display: flex;
  /* max-height: calc(100vh - 84px - 94px - 12px); */
  width: 100%;
}
</style>
