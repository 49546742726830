<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation persistent>
    <v-card>
      <v-card-text class="pt-5">
        <v-form ref="form" @submit="deny">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="tripRequest.approval.comments"
                outlined
                label="Add Comments"
                :rules="[(v) => !!v || 'Comments are required']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="primary" @click="deny()" :loading="saving" :disabled="saving || !tripRequest.approval.comments">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { APPROVE_TRIP_REQUEST, GET_TRIP_REQUESTS, GET_TRIP_REQUEST } from '@/store/modules/TripRequest/actions';

export default {
  name: 'TripDeny',
  inject: ['eventHub'],
  components: {},
  props: {
    tripRequest: Object,
  },
  data() {
    return {
      dialog: false,
      changesNeeded: false,
      saving: false,
      redirectToDashboard: false,
      redirectToCalendar: false,
      redirectToAssignments: false,
    };
  },
  created() {
    if (this.$router.history.current.query.dashboard) this.redirectToDashboard = true;
    if (this.$router.history.current.query.calendar) this.redirectToCalendar = true;
    if (this.$router.history.current.query.assignment) this.redirectToAssignments = true;
  },
  methods: {
    ...mapActions('tripRequest', [APPROVE_TRIP_REQUEST, GET_TRIP_REQUESTS, GET_TRIP_REQUEST]),
    ...mapMutations('tripRequest', ['updateTripRequest']),
    async deny() {
      //  -1: denied, 0: changes requested, 1: approved
      this.saving = true;
      try {
        await this.approveTripRequest({
          tripRequestId: this.tripRequest.id,
          approvalLevelId: this.tripRequest.approval.awaitingApproval.id,
          approved: this.changesNeeded ? 0 : -1,
          comments: this.tripRequest.approval.comments,
        });
        const updated = await this.getTripRequest(this.tripRequest.id);
        this.updateTripRequest(updated.tripRequest);
        this.dialog = false;
        if (this.changesNeeded) this.$myalert.success('Changes Requested');
        else this.$myalert.success('Trip Request Denied');
        let to = '/trip-requests';
        if (this.redirectToDashboard) to = '/dashboard';
        else if (this.redirectToCalendar) to = '/calendars';
        else if (this.redirectToAssignments) to = '/assignments';
        this.$router.push(to);
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
