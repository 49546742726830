<template>
  <v-row class="form-wrapper">
    <v-col v-if="loading">
      <Spinner />
    </v-col>
    <v-col cols="12" md="12" class="form-content" v-else>
      <div class="title pl-0" v-if="!duplicated">
        <v-alert
          v-if="tripRequest.status == -2 || tripRequest.status == -3"
          color="red lighten-2"
          outlined
          dense
          text
          type="warning"
          prominent
          ><h2>This trip has been {{ tripRequest.status == -2 ? 'denied' : 'canceled' }}</h2>
        </v-alert>
        <h6 class="text-h5 font-weight-bold inline">Trip Request #{{ tripRequest.id }}</h6>
        <div class="text-subtitle-2 inline ml-2">{{ submitted }}</div>

        <v-btn @click="printTrip" color="primary" class="right mobile-hide"><v-icon>mdi-printer</v-icon></v-btn>
      </div>
      <v-col
        cols="12"
        md="12"
        v-if="tripRequest.status == 1 && !tripRequest.approval.approved && tripRequest.recurrence.length > 0"
      >
        <v-alert outlined type="warning" icon="mdi-autorenew" text>
          This trip is recurring. Once approved a duplicate trip will be created for each of the selected dates.
        </v-alert>
      </v-col>

      <div class="section">
        <div class="text-h6 section-heading">General</div>
        <step-general
          ref="stepGeneral"
          :tripRequest="tripRequest"
          :tripRequestConfig="tripRequestConfig"
          :customFormFields="filteredCFFs[0]"
          :readonly="readonly"
          @tripTypeSelected="tripTypeSelected($event)"
          @handleCFFInput="handleCFFInput($event)"
        ></step-general>
      </div>

      <div class="section">
        <div class="text-h6 section-heading">Leave / Return</div>
        <step-leave-return
          :tripRequest="tripRequest"
          :tripRequestConfig="tripRequestConfig"
          :customFormFields="filteredCFFs[1]"
          :readonly="readonly"
          @handleCFFInput="handleCFFInput($event)"
          @preventSubmit="handlePreventSubmit($event)"
          @refresh="refreshTripRequest"
        ></step-leave-return>
      </div>

      <div class="section">
        <div class="text-h6 section-heading">Destination(s)</div>
        <step-destination
          :customFormFields="filteredCFFs[2]"
          :readonly="readonly"
          :tripRequest="tripRequest"
          @handleCFFInput="handleCFFInput($event)"
          class="section"
        ></step-destination>
      </div>

      <div class="section">
        <div class="text-h6 section-heading">Attendees</div>
        <step-attendees
          class="section"
          :tripRequest="tripRequest"
          :tripRequestConfig="tripRequestConfig"
          :customFormFields="filteredCFFs[3]"
          :readonly="readonly"
          @handleCFFInput="handleCFFInput($event)"
          @preventSubmit="handlePreventSubmit($event)"
        ></step-attendees>
      </div>

      <div class="section">
        <div class="text-h6 section-heading">Transportation</div>
        <step-transportation
          class="section"
          :tripRequest="tripRequest"
          :tripRequestConfig="tripRequestConfig"
          :customFormFields="filteredCFFs[4]"
          :readonly="readonly"
          @handleCFFInput="handleCFFInput($event)"
          @preventSubmit="handlePreventSubmit($event)"
        ></step-transportation>
      </div>

      <div class="section">
        <div class="text-h6 section-heading">Funding</div>
        <step-funding-sources
          class="section"
          :tripRequest="tripRequest"
          :tripRequestConfig="tripRequestConfig"
          :customFormFields="filteredCFFs[5]"
          :readonly="readonly"
          @handleCFFInput="handleCFFInput($event)"
        ></step-funding-sources>
      </div>

      <div class="section" v-if="showAdditionalInfo">
        <div class="text-h6 section-heading">Additional Info</div>
        <step-additional-info
          class="section"
          :tripRequest="tripRequest"
          :tripRequestConfig="tripRequestConfig"
          :customFormFields="filteredCFFs[6]"
          :readonly="readonly"
          @handleCFFInput="handleCFFInput($event)"
        ></step-additional-info>
      </div>

      <div class="section">
        <div class="text-h6 section-heading">Supporting Documents</div>
        <step-supporting-documents
          class="section"
          :tripRequest="tripRequest"
          :customFormFields="filteredCFFs[7]"
          :readonly="readonly"
          @handleCFFInput="handleCFFInput($event)"
          @refresh="refreshTripRequest"
        ></step-supporting-documents>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
import StepGeneral from './Step.General.vue';
import StepLeaveReturn from './Step.LeaveReturn.vue';
import StepDestination from './Step.Destination.vue';
import StepAttendees from './Step.Attendees.vue';
import StepTransportation from './Step.Transportation.vue';
import StepFundingSources from './Step.FundingSources.vue';
import StepAdditionalInfo from './Step.AdditionalInfo.vue';
import StepSupportingDocuments from './Step.SupportingDocuments.vue';
import { randomString } from '@/util';
import { mapActions } from 'vuex';
import { Spinner } from '@/components/shared';

export default {
  name: 'TripRequestReview',
  inject: ['eventHub'],
  components: {
    StepGeneral,
    StepLeaveReturn,
    StepDestination,
    StepAttendees,
    StepTransportation,
    StepFundingSources,
    StepAdditionalInfo,
    StepSupportingDocuments,
    Spinner,
  },
  props: {
    tripRequest: Object,
    complete: Object,
    tripRequestConfig: Object,
    filteredCFFs: Object,
    showAdditionalInfo: Boolean,
    duplicated: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('user', ['usersById', 'me']),
    ...mapGetters('tripRequest', ['currentTripRequestApprovalStatus']),
    submitted() {
      if (this.duplicated) return '';
      const user = this.usersById[this.tripRequest.submittedUser];
      if (!user) return '';

      const formattedDate = format(fromUnixTime(this.tripRequest.submittedTimestamp), 'MMM d, yyyy @ h:mm aaa');
      return `${user?.displayName} - ${formattedDate}`;
    },
    readonly() {
      if (this.me.is.superAdmin || this.me.is.transportationAdmin) {
        // superadmin have no restrictions
        return false;
      } else if (this.tripRequest.status < -1) {
        return true;
      } else if (['auto', 'full', 'partial', 'assigned'].includes(this.currentTripRequestApprovalStatus)) {
        // readonly if trip has any approval
        // auto approved is same with fully approved
        // assigned is same with fully approved but with different process
        return true;
      } else {
        return (
          !this.tripRequest.permissions.canEdit &&
          !this.tripRequest.permissions.canEditPreApproval &&
          !this.tripRequest.permissions.canEditMidApproval
        );
      }
    },
  },
  methods: {
    ...mapActions('tripRequest', ['printTrips']),
    ...mapActions('user', ['getUsers']),
    refreshTripRequest() {
      this.$emit('refreshTripRequest');
    },
    tripTypeSelected(tripTypeId) {
      this.$emit('tripTypeSelected', tripTypeId);
    },
    handlePreventSubmit(option) {
      this.$emit('handlePreventSubmit', option);
    },
    handleCFFInput(input) {
      this.$emit('handleCFFInput', input);
    },
    printTrip() {
      this.printTrips({ uuid: randomString(16), tripRequestIds: [this.tripRequest.id] });
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getUsers();
    } catch (e) {
      this.$myalert.error('Unable to retrieve user data. Please try again later.', true);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.form-wrapper {
  max-height: calc(100vh - 84px - 94px - 60px);
}
.form-content {
  overflow-y: auto;
  max-height: calc(100vh - 84px - 94px - 90px);
}
.banner + .form-content {
  max-height: calc(100vh - 84px - 94px - 130px);
}
.title {
  padding-top: 20px;
  padding-left: 12px;
}
.section {
  padding-top: 32px;
}
.section-heading {
  color: white;
  background-color: #4f5659;
  padding: 5px 0px;
  margin-bottom: 10px;
}
.section .text-h6 {
  padding-left: 12px;
}
.inline {
  display: inline-block;
}
.right {
  float: right;
}
@media (max-width: 768px) {
  .mobile-hide {
    display: none;
  }
}
</style>
