<template>
  <v-dialog v-model="dialog" max-width="1000px" no-click-animation persistent>
    <v-card>
      <v-card-title>Request For Quote</v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" @submit="send">
          <v-row dense>
            <v-col cols="10">
              <v-autocomplete
                v-model="$v.form.sendTo.$model"
                :items="addTrans"
                item-text="name"
                :label="title"
                multiple
                return-object
                outlined
                chips
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                outlined
                label="Reply To Email"
                :value="form.replyTo"
                v-model="$v.form.replyTo.$model"
                required
                :error-descriptions="handleErrors($v.form.replyTo)"
                @blur="$v.form.replyTo.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                outlined
                label="Subject"
                :value="form.subject"
                v-model="$v.form.subject.$model"
                required
                :error-descriptions="handleErrors($v.form.subject)"
                @blur="$v.form.subject.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                outlined
                label="Message"
                :value="form.content"
                v-model="$v.form.content.$model"
                required
                :error-descriptions="handleErrors($v.form.content)"
                @blur="$v.form.content.$touch()"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> Cancel </v-btn>
        <v-btn color="success" @click="send()"> Send </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { REQUEST_QUOTE } from '@/store/modules/TripRequest/actions';
import { GET_ADDITIONAL_TRANSPORTATIONS } from '@/store/modules/AdditionalTransportation/actions';

export default {
  name: 'RequestQuote',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: {},
  props: {
    tripRequest: Object,
    type: Number,
    defaultMessage: String,
  },
  data() {
    return {
      handleErrors,
      dialog: false,
      types: {
        '-3': { label: 'Approved Charters', check: 'charter' },
        '-2': { label: 'Contractors', check: 'contractor' },
      },
      selectAllAdditionalTransportations: false,
    };
  },
  validations: {
    form: {
      sendTo: { required },
      replyTo: { required },
      subject: { required },
      content: { required },
    },
  },
  computed: {
    ...mapGetters('additionalTransportation', ['additionalTransportations']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('user', ['me']),
    form() {
      return {
        sendTo: [],
        replyTo: this.me.email,
        subject: 'Request for Quote',
        content:
          (this.defaultMessage ? this.defaultMessage + '\n\n' : '') +
          `Trip #${this.tripRequest.id} \n` +
          (this.tripRequest.locationId ? `Location: ${this.locationsById[this.tripRequest.locationId].name} \n` : '') +
          `Trip Leave: ${this.leaveDate} @ ${this.leaveTime} \n` +
          `Trip Return: ${this.returnDate} @ ${this.returnTime} \n` +
          `Total Passengers: ${this.tripRequest.totalStudents + this.tripRequest.totalAdults} \n` +
          (this.tripRequest.destinationId && this.tripRequest.destinationId > 0
            ? `Destination: ${this.destinationsById[this.tripRequest.destinationId].name}`
            : ''),
      };
    },
    title() {
      return this.type < 0 && this.types[this.type] ? this.types[this.type].label : '';
    },
    addTrans() {
      if (this.type < 0)
        return this.additionalTransportations.filter(
          (e) => this.types[this.type] && this.types[this.type].check == e.type
        );
      else return [];
    },
    leaveDate() {
      if (!this.tripRequest.leaveDate) return '';
      const d = new Date(this.tripRequest.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd');
    },
    leaveTime() {
      if (!this.tripRequest.leaveTime) return '';
      const s = this.tripRequest.leaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    returnDate() {
      if (!this.tripRequest.returnDate) return '';
      const d = new Date(this.tripRequest.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd');
    },
    returnTime() {
      if (!this.tripRequest.returnTime) return '';
      const s = this.tripRequest.returnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
  },
  methods: {
    ...mapActions('tripRequest', [REQUEST_QUOTE]),
    ...mapActions('additionalTransportation', [GET_ADDITIONAL_TRANSPORTATIONS]),
    async send() {
      try {
        const r = await this.requestQuote({
          ...this.$v.form.$model,
          // sendTo: this.$v.form.$model.sendTo.filter((e) => e.email).map((e) => e.email),
          tripRequestId: this.tripRequest.id,
        });

        if (r && r.done) {
          this.$myalert.success('Email sent');
          this.dialog = false;
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
